var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"cursor":"pointer"}},[_c('div',{staticStyle:{"margin-bottom":"10px","display":"flex","align-items":"center","justify-content":"center"}},[_c('img',{staticClass:"img-fluid",staticStyle:{"width":"100px"},attrs:{"src":_vm.$store.state.logoImageUrl,"alt":""}})])]),_c('div',{staticClass:"side-link"},[_c('ul',[_c('li',{staticStyle:{"padding-right":"5px"},on:{"click":function($event){return _vm.$router.push({path: '/'})}}},[_c('i',{staticClass:"el-icon-info",staticStyle:{"padding-right":"5px"}}),_vm._v("Website ")]),_c('li',{class:{ active: _vm.$route.name == 'Home' },staticStyle:{"padding-right":"5px"},on:{"click":function($event){return _vm.$router.push({path: '/ovalspace/os-admin/'})}}},[_c('i',{staticClass:"el-icon-menu",staticStyle:{"padding-right":"5px"}}),_vm._v(" Dashboard ")]),_c('li',{class:{ active: _vm.$route.name == 'Reservations' },staticStyle:{"padding-right":"5px"},on:{"click":function($event){return _vm.$router.push({path: '/ovalspace/os-admin/reservations'})}}},[_c('i',{staticClass:"el-icon-s-data",staticStyle:{"padding-right":"5px"}}),_vm._v(" Reservations ")]),_c('li',{class:{ active: _vm.$route.name == 'Bookings' },on:{"click":function($event){return _vm.$router.push({path: '/ovalspace/os-admin/bookings'})}}},[_c('i',{staticClass:"el-icon-user",staticStyle:{"padding-right":"5px"}}),_vm._v(" Bookings ")]),_c('li',{class:{ 
              active: _vm.$route.name == 'Categories' ||
              _vm.$route.name == 'Products' ||
              _vm.$route.name == 'AddProduct' ||
              _vm.$route.name == 'EditProduct'||
              _vm.$route.name == 'Colors' ||
              _vm.$route.name == 'AddColor' ||
              _vm.$route.name == 'EditColor', 
            },staticStyle:{"padding-right":"5px"},on:{"click":function($event){return _vm.$router.push({path: '/ovalspace/os-admin/categories'})}}},[_c('i',{staticClass:"el-icon-s-grid",staticStyle:{"padding-right":"5px"}}),_vm._v("Room Categories ")]),_c('li',{class:[_vm.$store.state.userRoles == 'ADMIN' ? 'show_list': 'hide_list', { active: _vm.$route.name == 'Banners' }],staticStyle:{"padding-right":"5px"},on:{"click":function($event){return _vm.$router.push({path: '/ovalspace/os-admin/banners'})}}},[_c('i',{staticClass:"el-icon-s-shop",staticStyle:{"padding-right":"5px"}}),_vm._v(" Gallery ")]),_c('li',{class:[_vm.$store.state.userRoles == 'ADMIN' ? 'show_list': 'hide_list', { active: _vm.$route.name == 'Users' }],on:{"click":function($event){return _vm.$router.push({path: '/ovalspace/os-admin/users'})}}},[_c('i',{staticClass:"el-icon-user-solid",staticStyle:{"padding-right":"5px"}}),_vm._v(" Team Members ")]),_c('li',{class:{ active: _vm.$route.name == 'contactUsMessages' },on:{"click":function($event){return _vm.$router.push({path: '/ovalspace/os-admin/contact-us-messages'})}}},[_c('i',{staticClass:"el-icon-s-operation",staticStyle:{"padding-right":"5px"}}),_vm._v(" contact Us Messages ")]),_c('li',{class:{ active: _vm.$route.name == 'guestFeedBacks' },on:{"click":function($event){return _vm.$router.push({path: '/ovalspace/os-admin/guest-feedbacks'})}}},[_c('i',{staticClass:"el-icon-s-operation",staticStyle:{"padding-right":"5px"}}),_vm._v(" Guest Feedbacks ")]),_c('li',{class:{ active: _vm.$route.name == 'NewsLetters' },on:{"click":function($event){return _vm.$router.push({path: '/ovalspace/os-admin/newsletters'})}}},[_c('i',{staticClass:"el-icon-s-operation",staticStyle:{"padding-right":"5px"}}),_vm._v(" NewsLetters ")]),_c('li',{on:{"click":_vm.logout}},[_c('i',{staticClass:"fas fa-arrow-left",staticStyle:{"padding-right":"5px"}}),_vm._v(" Logout ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }