<template>
  <div>
        <div style="cursor: pointer">
          <div style="
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;">
            <img
              :src="$store.state.logoImageUrl"
              style="width: 100px;"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
        <div class="side-link">
          <ul>
            <li
              style="padding-right:5px"
              @click="$router.push({path: '/'})"
            >
              <i
                style="padding-right:5px"
                class="el-icon-info"
              ></i>Website
            </li>
            <li
              style="padding-right:5px"
              @click="$router.push({path: '/ovalspace/os-admin/'})"
              :class="{ active: $route.name == 'Home' }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-menu"
              ></i> Dashboard
            </li>

            <li
              style="padding-right:5px"
              @click="$router.push({path: '/ovalspace/os-admin/reservations'})"
              :class="{ active: $route.name == 'Reservations' }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-s-data"
              ></i> Reservations
            </li>

            <li
              @click="$router.push({path: '/ovalspace/os-admin/bookings'})"
              :class="{ active: $route.name == 'Bookings' }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-user"
              ></i> Bookings
            </li>
            
            <li
              style="padding-right:5px"
              @click="$router.push({path: '/ovalspace/os-admin/categories'})"
              :class="{ 
                active: $route.name == 'Categories' ||
                $route.name == 'Products' ||
                $route.name == 'AddProduct' ||
                $route.name == 'EditProduct'||
                $route.name == 'Colors' ||
                $route.name == 'AddColor' ||
                $route.name == 'EditColor', 
              }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-s-grid"
              ></i>Room Categories
            </li>

            <li
              style="padding-right:5px"
              @click="$router.push({path: '/ovalspace/os-admin/banners'})"
              :class="[$store.state.userRoles == 'ADMIN' ? 'show_list': 'hide_list', { active: $route.name == 'Banners' }]"
            >
                <i
                  style="padding-right:5px"
                  class="el-icon-s-shop"
                ></i> Gallery
            </li>

            <li
              @click="$router.push({path: '/ovalspace/os-admin/users'})"
              :class="[$store.state.userRoles == 'ADMIN' ? 'show_list': 'hide_list', { active: $route.name == 'Users' }]"
            >
                <i
                  style="padding-right:5px"
                  class="el-icon-user-solid"
              ></i> Team Members
            </li>

            <li
              @click="$router.push({path: '/ovalspace/os-admin/contact-us-messages'})"
              :class="{ active: $route.name == 'contactUsMessages' }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-s-operation"
              ></i> contact Us Messages
            </li>

            <li
              @click="$router.push({path: '/ovalspace/os-admin/guest-feedbacks'})"
              :class="{ active: $route.name == 'guestFeedBacks' }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-s-operation"
              ></i> Guest Feedbacks
            </li>

            <li
              @click="$router.push({path: '/ovalspace/os-admin/newsletters'})"
              :class="{ active: $route.name == 'NewsLetters' }"
            >
              <i
                style="padding-right:5px"
                class="el-icon-s-operation"
              ></i> NewsLetters
            </li>

            <li @click="logout">
              <i
                style="padding-right:5px"
                class="fas fa-arrow-left"
              ></i> Logout
            </li>

          </ul>
        </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      
    };
  },

  methods: {
    logout() {
      // remove user from local storage to log user out
      localStorage.removeItem("user");
      location.reload(true);
    },
  },
};
</script>

<style scoped>

.side-link ul {
  list-style: none;
  padding: 0px;
}
.side-link ul li {
  border-bottom: 1px solid grey;
  padding-top: 30px;
  padding-bottom: 10px;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 300 !important;
}
.side-link .active {
  border-left: 6px solid #ff1f54;
  color: grey !important;
  font-weight: 300;
}
.side-link ul li:hover {
  padding-left: 30px;
  transition: 0.5s;
  color: grey;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
}
</style>
